html {
  scroll-behavior: smooth;
}

@media (prefers-color-scheme: dark) {
  /* html { */
  /*   /\* Put dark BG here *\/ */
  /* } */
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.-ml-halfscreen {
  margin-left: -50vw;
}

.-mr-halfscreen {
  margin-right: -50vw;
}

model-viewer::part(default-progress-bar) {
  display: none;
  background-color: #000;
}

model-viewer::part(default-progress-mask) {
  background-color: #000;
  display: none;
}

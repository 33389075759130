@font-face {
  font-family: 'Centrale Sans';
  src: url('/fonts/centralesans-book-webfont.woff2') format('woff2'),
    url('/fonts/centralesans-book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Centrale Sans';
  src: url('/fonts/centralesans-medium-webfont.woff2') format('woff2'),
    url('/fonts/centralesans-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Centrale Sans';
  src: url('/fonts/centralesans-bold-webfont.woff2') format('woff2'),
    url('/fonts/centralesans-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
